export default class ScenarioRequestModel {
  constructor(
    public id?: number,
    public name = "",
    public description = "",
    public script = ""
  ) {}

  static ofSource(model: ScenarioRequestModel): ScenarioRequestModel {
    return new ScenarioRequestModel(
      model.id,
      model.name,
      model.description,
      model.script
    );
  }
}

export class ScenarioInfoRequestModel {
  constructor(public id?: number, public name = "", public description = "") {}

  static ofSource(model: ScenarioInfoRequestModel): ScenarioInfoRequestModel {
    return new ScenarioInfoRequestModel(
      model.id,
      model.name,
      model.description
    );
  }
}

export class ScenarioScriptRequestModel {
  constructor(public id?: number, public script = "") {}

  static ofSource(model: ScenarioRequestModel): ScenarioScriptRequestModel {
    return new ScenarioScriptRequestModel(model.id, model.script);
  }
}
