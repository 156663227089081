

























































































import { Component, Emit, Prop, Ref, Vue, Watch } from "vue-property-decorator";
import { cloneDeep } from "lodash";

import InfraCommandsSideBar from "@/application-manager/components/InfraCommandsSideBar.vue";
import { ScenarioInfoRequestModel } from "@/application-manager/models/ScenarioRequestModel";
import InfraCommandResponseModel from "@/application-manager/models/InfraCommandResponseModel";
import ValidUtil from "@/shared/utils/ValidUtil";
import { VueForm } from "@/shared/types/ExtendedVueType";
import { MenuItemsApplicationManager } from "@/shared/models";

@Component({
  components: { InfraCommandsSideBar },
})
export default class ScenarioForm extends Vue {
  @Prop() value!: ScenarioInfoRequestModel;
  @Prop({ default: "" }) title!: string;
  @Prop({ default: false }) isCreatePage!: boolean;

  @Ref("form") readonly form!: VueForm;

  localValue: ScenarioInfoRequestModel = ScenarioInfoRequestModel.ofSource(
    this.value
  );
  isValid = false;
  isEditableFields = false;

  readonly MenuItemsApplicationManager = MenuItemsApplicationManager;
  readonly requiredRule = [
    ValidUtil.required(this.$lang("validation.required")),
  ];

  get isSavingInProgress(): boolean {
    return this.$store.state.scenarioStore.isSavingInProgress;
  }

  get infraCommands(): Array<InfraCommandResponseModel> {
    return this.$store.state.infraCommandsStore.infraCommands;
  }

  @Watch("localValue", { deep: true })
  private watchLocalValue(value: ScenarioInfoRequestModel) {
    this.$emit("input", value);
  }

  @Watch("isValid")
  private watchIsValid(value: boolean) {
    this.$emit("validateForm", value);
  }

  async handleSave() {
    if (!this.form.validate()) {
      return;
    }

    this.emitSave();
    this.isEditableFields = false;
  }

  handleEditing() {
    if (!this.isEditableFields) {
      this.isEditableFields = true;
    } else {
      this.handleSave();
    }
  }

  handleCancelEditing() {
    this.isEditableFields = false;
    this.localValue = cloneDeep(this.value);
  }

  @Emit("save") emitSave(): ScenarioInfoRequestModel {
    return this.localValue;
  }
}
